import * as React from "react"
import HCenterRow from "../components/h-center-row"

import BlueIndexLink from "../components/link/blue-index-link"
import PageTitle from "../components/page-title"
import ContentLayout from "../layouts/content-layout"
import Seo from "../layouts/seo"
import IDataPageProps from "../interfaces/data-page-props"

export default function Page({ location }: IDataPageProps) {
  return (
    <ContentLayout title="Page Not Found" showTitle={false} location={location}>
      <HCenterRow className="mt-16">
        <PageTitle title="The page you're looking for can't be found." />
      </HCenterRow>
      <HCenterRow>
        <BlueIndexLink
          href="/sitemap"
          ariaLabel="Click to view site map"
          className="mt-16"
        >
          See our site map
        </BlueIndexLink>
      </HCenterRow>
    </ContentLayout>
  )
}

export const Head = () => <Seo title="Page Not Found" />
